import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { globalAction } from '../../store/actions';
import { projectAction } from '../../store/actions';
import { mapAction } from '../../store/actions';
import './style.scss';

import * as api from '../../utils/api';

import { L, map, tileLayer, Browser, marker, icon, polyline, control } from 'leaflet';
//import { Legend } from '../../assets/js/leaflet.legend.js';
//import { Legend } from 'leaflet-legend';

import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
	view: {
		position: 'relative',
		width: '100%',
		height: '100vh',
		overflow: 'hidden',
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	viewShift: {
		position: 'relative',
		height: '100vh',
		overflow: 'hidden',
		width: `100%`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
	annotation_button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20,
		marginTop: 20,
    },
	measure_done_button: {
        fontSize: 12,
        fontWeight: 300,
		display: "flex"
    },
}));

function PoleRemapView(props) {
	/* state variables */
	const classes = useStyles();

	let projectID = null;
    let datasetID = null;
    let dataset = null;
	let pole_mapping = null;
	let leaflet_map = null;
	let markers = [];
	let currMarker = null;
	let kml_pole_icon = null;
	let kml_pole_selected_icon = null;
	let kml_pole_bad_icon = null;
	let kml_pole_orphan_icon = null;

	if (api.getParameterByName("project_id") && api.getParameterByName("project_id") !== "") {
		projectID = api.getParameterByName("project_id");
	}

    if (api.getParameterByName("dataset_id") && api.getParameterByName("dataset_id") !== "") {
		datasetID = api.getParameterByName("dataset_id");
	}

    if (!datasetID || !projectID) {
        props.history.goBack();
    }
	
	const [windowResolution, setWindowResolution] = React.useState([window.innerWidth, window.innerHeight]);
	const [saveButtonText, setSaveButtonText] = useState('Save');
	const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
	const [exportButtonText, setExporttButtonText] = useState('Generate');
	const [exportButtonDisabled, setExportButtonDisabled] = useState(false);

	async function getDataset() {
		let userToken = localStorage.getItem("userToken");
		if (api.isSharing()) {
			userToken = api.getParameterByName("share_token");
		}
		if (userToken) {
			let response = await api.getProjectDatasetByID({id: datasetID, project_id: projectID, token: userToken});
			
			if (response.data && !response.data.error) {
				dataset = response.data;
				showPoleMaping();
			}
			else {
				redirectBack();
			}
		}
		else {
			redirectToLogin();
		}
	}

	function redirectToLogin() {
        props.history.replace("/");
    }

	function redirectBack() {
		props.history.goBack();
	}

	function initMapViewer(force) {
		if (leaflet_map && !force) return;
		let mapContainer = document.getElementById("map_container");
		if (!mapContainer) return;

		const myAPIKey = '023f31a7ebc0492dac8042ef6a1d83ae';
		const isRetina = Browser.retina;
		var streets_url = `https://maps.geoapify.com/v1/tile/osm-liberty/{z}/{x}/{y}.png?apiKey=${myAPIKey}`;
		var retinaUrl = `https://maps.geoapify.com/v1/tile/osm-liberty/{z}/{x}/{y}@2x.png?apiKey=${myAPIKey}`;
		var satellite_url = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';

		const initialState = {
			lng: 11,
			lat: 49,
			zoom: 4,
		};

		var satellite = tileLayer(satellite_url,
		{
			attribution:
				'PoleHawk',
			maxZoom: 20,
			id: 'osm-liberty',
		});

		var streets = tileLayer(isRetina ? retinaUrl : streets_url,
		{
			attribution:
				'PoleHawk',
			maxZoom: 20,
			id: 'osm-liberty',
		});

		var baseMaps = 
		{
			"Satellite": satellite,
			"Streets": streets
		};
	
		leaflet_map = map(mapContainer, 
			{
				zoomControl: false,
				layers: [satellite, streets]
			}).setView(
			[initialState.lat, initialState.lng],
			initialState.zoom
		);	

		control.layers(baseMaps).addTo(leaflet_map);

		//	Show the legend
		control.Legend(
		{
			position: "bottomleft",
			collapsed: false,
			symbolWidth: 24,
			opacity: 1,
			column: 1,
			legends:
			[
				{
					label: "Lidar Pole",
					type: "image",
					url: "/images/pole0-grey.png"
				},
				{
					label: "KMZ Pole - paired",
					type: "image",
					url: "/images/pole1-green.png"
				},
				{
					label: "KMZ Pole - selected",
					type: "image",
					url: "/images/pole1-blue.png"
				},
				{
					label: "KMZ Pole - unpaired",
					type: "image",
					url: "/images/pole1-red.png"
				}
			]
		}).addTo(leaflet_map);

	}

	useEffect(() => {

		// Load the Leaflet legend addon
		const script = document.createElement('script');
		script.src = '/js/leaflet.legend.js';
		script.async = true;
		document.body.appendChild(script);
		script.onload = function() {
			initMapViewer();
			getDataset();
		}
		
	}, [windowResolution]);

	function showPoleMaping() {

		// Get the pole mapping json file name from the dataset's file file
		let poles_map_json_file_name = '';
		dataset.files.forEach(file => {
			if(file.name.endsWith('.json'))
			{
				poles_map_json_file_name = file.name;
			}
		});

		// Build the url to the poles map json file for this dataset
		let storage_url = '';
		if(dataset.dataset.storage.indexOf('storage_s3') > -1)
		{
			storage_url = `${api.s3Path}globemap_s3/`;
		}
		else 
		{
			storage_url = `${api.serverPath}globemap/`;
		}	
		let poles_map_json_url = `${storage_url}datasets/${dataset.dataset.id}/${poles_map_json_file_name}`;

		// Add a timestamp as a cache busting query string when fetching the json file. Otherwise, saved changes won't get loaded when this report is re-edited. 
		poles_map_json_url += '?' + Date.now();

		// Get the poles map json
		fetch(poles_map_json_url)
		.then(response => response.json())
		.then(data => 
		{
			// The poles json loaded...
			
			// parse the mapping data from the json
			pole_mapping = (typeof data === "object" && data !== null)? data: JSON.parse(data);
			window.pole_mapping = pole_mapping;
			const lidar_poles = pole_mapping.lidar_poles;
			const kml_poles = pole_mapping.kml_poles;
			let fit_bounds_points = [];

			// Create a lidar pole icon style
			var lidar_pole_icon = icon(
			{
				iconUrl: '/images/pole0-grey.png',
				iconSize:     [30, 30],
				iconAnchor:   [15, 15],
			});

			// And all the lidar poles to the map
			for (let i = 0; i < lidar_poles.length; i++)
			{			
				// Add the pole marker
				var loopLidarMarker = marker([lidar_poles[i].lat, lidar_poles[i].lon], {icon: lidar_pole_icon,riseOnHover:true}).addTo(leaflet_map).on('click', onLidarMarkerClick);//.bindPopup(`Lidar Pole [${i}]`);
				loopLidarMarker.index = i;

				// Add this point to the bounds array to fit the map to	
				fit_bounds_points.push([lidar_poles[i].lat, lidar_poles[i].lon]);
			}

			kml_pole_icon = icon(
			{
				iconUrl: '/images/pole1-green.png',
				iconSize:     [30, 30],
				iconAnchor:   [15, 30],
			});
		
			kml_pole_selected_icon = icon(
			{
				iconUrl: '/images/pole1-blue.png',
				iconSize: [30, 30],
				iconAnchor: [15, 30]
			});
		
			kml_pole_bad_icon = icon(
			{
				iconUrl: '/images/pole1-red.png',
				iconSize: [30, 30],
				iconAnchor: [15, 30]
			});
		
			kml_pole_orphan_icon = icon(
			{
				iconUrl: '/images/pole1-red.png',
				iconSize: [30, 30],
				iconAnchor: [15, 30]
			});
			
			// And all the KML poles to the map					
			for (let i = 0; i < kml_poles.length; i++)
			{				
				// Add the pole marker
				var defaultIcon = (kml_poles[i].lidar_pole === -1)? kml_pole_orphan_icon: kml_pole_icon;
				var loopMarker = marker([kml_poles[i].point.lat, kml_poles[i].point.lon], 
				{
					icon: defaultIcon,
					riseOnHover:true
				}).addTo(leaflet_map)
				.bindTooltip(kml_poles[i].name, 
				{
					permanent: true, 
					direction: 'right'
				})
				.on('click', onKMLMarkerClick);

				loopMarker.defaultIcon = defaultIcon;
				loopMarker.state = "default";
				loopMarker.index = i;
				markers.push(loopMarker);
				
				
				// If this KML is mapped to a lidar pole, add a line between the poles
				if(kml_poles[i].lidar_pole > -1)
				{
					let points =
					[
						[lidar_poles[kml_poles[i].lidar_pole].lat, lidar_poles[kml_poles[i].lidar_pole].lon],
						[kml_poles[i].point.lat, kml_poles[i].point.lon],
					];
					var loopLine = polyline(points, {color: 'blue'}).addTo(leaflet_map);
					markers[i].line = loopLine;
				}
				

				// Add this point to the bounds array to fit the map to	
				fit_bounds_points.push([kml_poles[i].point.lat, kml_poles[i].point.lon]);
			}

			// Fit the map to the collection of all poles
			leaflet_map.fitBounds(fit_bounds_points);


		})
		.catch(error => {
			// Handle any errors
			console.error('Error:', error);
		});

    }	

	function onKMLMarkerClick(e)
	{
		//	get the marker that was clicked
		var clickedMarker = e.target;

		//	If this isn't the current marker and there is a current marker
		//	then turn off the current marker
		if(currMarker && clickedMarker !== currMarker)
		{
			currMarker.state = "default";
		 	currMarker.setIcon(currMarker.defaultIcon);
		}
		//	Handle the state for this marker
		if(clickedMarker.state === "default")
		{
			clickedMarker.state = "remapping";
			clickedMarker.setIcon(kml_pole_selected_icon);
			currMarker = clickedMarker;
		}
		else
		{
			clickedMarker.state = "default";
			clickedMarker.setIcon(clickedMarker.defaultIcon);
			currMarker = null;
		}
	}

	function onLidarMarkerClick(e)
	{
		//	if no kml marker is currently selected, do nothing and return
		if(!currMarker) return;

		//	since there is a currently selected kml marker begin to process it
		var currLidarMarker = e.target;
		var index = currMarker.index;

		//	If this is the same lidar pole that was already selected for this kml pole
		//	then remove it and set the current pole as an orphan, if this is a new lidar
		//	pole, then update the kml pole with this lidar pole.
		var pole_mapping = window.pole_mapping;
		if(pole_mapping.kml_poles[index].lidar_pole == currLidarMarker.index)
		{
			//	- remove the lidar pole from the kml pole
			pole_mapping.kml_poles[index].lidar_pole = -1;			

			//	- remove it's old visible line
			if(currMarker.line)
				currMarker.line.remove();

			//	- set it's line to null
			currMarker.line = null;

			//	- turn off the currently selected kml marker and set the defaultMarker to the regular marker
			currMarker.state = "default";
			currMarker.defaultIcon = kml_pole_orphan_icon;
			currMarker.setIcon(currMarker.defaultIcon);

			window.pole_mapping = pole_mapping;
		}
		else
		{
			//	- 	check to see if there is already a kml pole with this same lidar pole, if there is, then
			//		remove the lidar from the other kmz and remove it's line and set it as an orphan
			var currLidarID = currLidarMarker.index;
			for(var a=0, alen= markers.length; a<alen; ++a)
			{
				var tempMarker = markers[a];
				var tempPole = pole_mapping.kml_poles[a];
				if(tempPole.lidar_pole !== currLidarID) continue;

				tempPole.lidar_pole = -1;
				if(tempMarker.line) tempMarker.line.remove();
				tempMarker.line = null;

				tempMarker.state = "default";
				tempMarker.defaultIcon = kml_pole_orphan_icon;
				tempMarker.setIcon(tempMarker.defaultIcon);
			}
			
			//	- set it's lidar_pole to this pole
			pole_mapping.kml_poles[index].lidar_pole = currLidarMarker.index;

			//	- remove it's old visible line
			if(currMarker.line)
				currMarker.line.remove();

			//	- draw a new line
			let points =
			[
				[
					pole_mapping.lidar_poles[currLidarMarker.index].lat,
					pole_mapping.lidar_poles[currLidarMarker.index].lon
				],
				[
					pole_mapping.kml_poles[index].point.lat,
					pole_mapping.kml_poles[index].point.lon
				],
			];
			var tempLine = polyline(points, {color: 'blue'}).addTo(leaflet_map);

			//	- set it's line to this line
			currMarker.line = tempLine;

			//	- turn off the currently selected kml marker and set the defaultMarker to the regular marker
			currMarker.state = "default";
			currMarker.defaultIcon = kml_pole_icon;
			currMarker.setIcon(currMarker.defaultIcon);

			window.pole_mapping = pole_mapping;
		}

		//	turn off the current marker
		currMarker = null;
	}

	async function onSaveClick()
	{
		// disable the button until save is complete
		setSaveButtonText('Saving...');
		setSaveButtonDisabled(true);

		// Save the updated json to the backend
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.savePoleRemapReport({
				token: userToken,
				dataset_id: datasetID,
				pole_map_json: window.pole_mapping
			});

            if (response.data && !response.data.error) {					
				toast("Remap saved");				
            }
            else {
                toast("Could not save");
				console.log(response)
            }

			setSaveButtonText('Save');
			setSaveButtonDisabled(false);
        }
        else {
            redirectToLogin();
        }

	}

	// Export button click
	async function onExportClick()
	{	
		// disable the button until save is complete
		setExporttButtonText('Generating...');
		setExportButtonDisabled(true);

		// Save the updated json to the backend
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.exportPoleRemapReport({
				token: userToken,
				dataset_id: datasetID
			});

            if (response.data && !response.data.error) {
					toast("KMZ and CSV download files have been updated.");
					//addToProject(response.data.id);									
            }
            else {
                toast("Could not generate KMZ and CSV download files");
				console.log(response)
            }

			setExporttButtonText('Generate');
			setExportButtonDisabled(false);
        }
        else {
            redirectToLogin();
        }

	}

	// async function addToProject(datasetID) {
    //     let userToken = localStorage.getItem("userToken");
    //     if (userToken) {
    //         let response = await api.addDataset({token: userToken, id: projectID, dataset_id: datasetID + ""});

    //         if (response.data && !response.data.error) {
    //             toast("KML dataset exported");
    //         }
	// 		else
	// 		{
	// 			toast("Could not export");
	// 		}
    //     }
    //     else {
    //         redirectToLogin();
    //     }
    // }


	return (
		<main className={clsx(classes.view, {
			[classes.viewShift]: props.drawerVisibility,
		})}>
			<div className={`map-view`}>
					
				<div className="map-container" id="map_container"></div>
				
			</div>

			<div className={`map-buttons`}>
				<button value="save" disabled={saveButtonDisabled} onClick={onSaveClick}>{saveButtonText}</button>
				<button value="export" disabled={exportButtonDisabled} onClick={onExportClick}>{exportButtonText}</button>
			</div>


		</main >
	);
}

const mapStateToProps = state => ({
	drawerVisibility: state.global.drawerVisibility,
	mapType: state.map.mapType,
	selectedProject: state.project.selectedProject,
	datasetList : state.project.datasetList,
	userInfo: state.global.userInfo
});

PoleRemapView.propTypes = {
	drawerVisibility: PropTypes.bool.isRequired,
	mapType: PropTypes.number.isRequired,
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, mapAction),
	connect(mapStateToProps, projectAction),
)(PoleRemapView);
import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';

import './style.scss';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
	root: {
        padding: 10,
        overflow: "auto"
	},
    info_section: {
        position: 'absolute',
        marginTop: '20px',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 100px)',
    },
    panel_label : {
        color: "#2196f3",
        fontSize: 20,
        marginTop: 3,
        textAlign: 'left',
    },
}));

function ReportPanel(props) {
	const classes = useStyles();

    function showImageGrouper() {
        if (props.onShowReportGenerator && props.selectedProject && props.selectedProject.project.id + "" !== "-1") {
            props.onShowReportGenerator("image_grouper");
        }
        else {
            toast("Please select project first");
        }
    }

    function showMapReport() {
        if (props.onShowReportGenerator && props.selectedProject && props.selectedProject.project.id + "" !== "-1") {
            props.onShowReportGenerator("map");
        }
        else {
            toast("Please select project first");
        }
    }

    function showImageReport() {
        if (props.onShowReportGenerator && props.selectedProject && props.selectedProject.project.id + "" !== "-1") {
            props.onShowReportGenerator("image");
        }
        else {
            toast("Please select project first");
        }
    }

    function showLidarReport() {
        if (props.onShowReportGenerator && props.selectedProject && props.selectedProject.project.id + "" !== "-1") {
            props.onShowReportGenerator("lidar");
        }
        else {
            toast("Please select project first");
        }
    }

    function showLidarDem() {
        if (props.onShowReportGenerator && props.selectedProject && props.selectedProject.project.id + "" !== "-1") {
            props.onShowReportGenerator("lidar_dem");
        }
        else {
            toast("Please select project first");
        }
    }

    function showPoleRemapReport() {
        if (props.onShowReportGenerator && props.selectedProject && props.selectedProject.project.id + "" !== "-1") {
            props.onShowReportGenerator("pole_remap");
        }
        else {
            toast("Please select project first");
        }
    }

	return (
		<div className={classes.root}>
            <div className={classes.panel_label}>Tools</div>
            <div className={classes.info_section}>
                <div className='report_part'>
                    <div className='report_panel_item' onClick={showLidarReport}>
                        <div className='report_label'>
                            Lidar Report
                        </div>
                        <div className="report_icon"><div className='icon left_arrow_icon'></div></div>
                    </div>
                    <div className='report_panel_item' onClick={showLidarDem}>
                        <div className='report_label'>
                            Lidar to DEM
                        </div>
                        <div className="report_icon"><div className='icon left_arrow_icon'></div></div>
                    </div>
                    <div className='report_panel_item' onClick={showImageReport}>
                        <div className='report_label'>
                            Image Report
                        </div>
                        <div className="report_icon"><div className='icon left_arrow_icon'></div></div>
                    </div>
                    <div className='report_panel_item' onClick={showMapReport}>
                        <div className='report_label'>
                            Map Report
                        </div>
                        <div className="report_icon"><div className='icon left_arrow_icon'></div></div>
                    </div>
                    <div className='report_panel_item' onClick={showImageGrouper}>
                        <div className='report_label'>
                            Image Grouper
                        </div>
                        <div className="report_icon"><div className='icon left_arrow_icon'></div></div>
                    </div>
                    <div className='report_panel_item' onClick={showPoleRemapReport}>
                        <div className='report_label'>
                            Pole Remap Report
                        </div>
                        <div className="report_icon"><div className='icon left_arrow_icon'></div></div>
                    </div>
                </div>
            </div>
		</div>
	);
}

const mapStateToProps = state => ({
    userInfo: state.global.userInfo,
    selectedProject : state.project.selectedProject,
});

ReportPanel.propTypes = {
}
export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(ReportPanel);
